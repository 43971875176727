// import logo from './logo.svg';
import heroImg from './hero.jpg';
import './App.css';
import { useState } from 'react';

import ableton from './media/ableton_mondriaan.png';
import maxForLive from './media/max_for_live_mondriaan.png';
import piano from './media/piano_mondriaan.png';
import theory from './media/theory_mondriaan.png';
import julienSquare from './media/julien2_square.jpeg';
import teachingThumbnail from './media/teaching_thumbnail.png';
import soundDesignLogo from './media/sound_design.png';
import musicProductionLogo from './media/music_production.png';
import audioVisualLogo from './media/audio_visual.png';
import webThumbnail from './media/web_thumbnail.png';

import etienneMachineScreenshot from './media/Etienne_Machine_Screenshot.png';
import asherScreenshot from './media/Asher_Screenshot.png';
// import woodRecordsScreenshot from './media/Wood_Records_Screenshot.png';
import recipeScreenshot from './media/Recipe_Screenshot.png';
import { Link, useSearchParams } from 'react-router-dom';

function App() {
  const [searchParams] = useSearchParams({ pos: 0, page: 'home' });
  const position = searchParams.get("pos");
  const page = searchParams.get("page");

  const createToLink = (positionValue, pageValue) => {
    return `?pos=${positionValue}&page=${pageValue}`;
  };

  // const [position, setPosition] = useState(0);
  // const [page, setPage] = useState('home');

  // function stateHandler(pos, page) {
  //   // setPosition(pos);
  //   setPage(page);
  // }

  return (
    <div className={position === '1' ? 'position1 App' : position === '2' ? 'position2 App' : 'position0 App'}>
      <div className="row" style={{ height: '100%' }}>
        <div>
          <div className="container-fluid">
            <div>
              <div id='hero' style={{ backgroundImage: `url(${heroImg})` }}>
                <div id='heroTextContainer'>
                  <h1>JPKVOID</h1>
                  <h2>MUSIC & WEB SERVICES</h2>
                  <Link to={createToLink('1', page)}>
                    <span id='menuButton' class="material-symbols-outlined">
                      arrow_forward
                    </span>
                  </Link>
                </div>
              </div>
              <ul id='nav'>
                <Link className='navItem red' to={createToLink('2', 'music')} style={{ textDecoration: 'none' }}>
                  <li className="col-12 navItem" style={{ background: position !== 2 ? null : page === 'music' ? '#FF5757' : null }}>

                    MUSIC
                  </li>
                </Link>

                <Link className='navItem yellow' to={createToLink('2', 'web')} style={{ textDecoration: 'none' }}>
                  <li className="col-12 navItem" style={{ background: position !== 2 ? null : page === 'web' ? '#FFDE59' : null }}>WEB</li>
                </Link>

                <Link className='navItem blue' to={createToLink('2', 'about')} style={{ textDecoration: 'none' }}>
                  <li className="col-12 navItem" style={{
                    color: 'inherit'
                  }}>ABOUT</li>
                </Link>

                <Link className='navItem' to={createToLink('0', 'home')} style={{ textDecoration: 'none' }}>
                  <li className="col-12 navItem hideOnDesktop">HOME</li>
                </Link>
              </ul>
              <div id='contentContainer'>

                <Link to={createToLink('1', page)}>
                  <span style={{ position: 'fixed', background: '#FFFFFF80', zIndex: '9999' }} class="material-symbols-outlined">
                    arrow_back
                  </span>
                </Link>

                <div style={{ marginTop: '6.5rem' }}>
                  {page === 'music' ? <Music /> : page === 'web' ? <Web /> : page === 'about' ? <About /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Music = () => {

  const [musicTab, setMusicTab] = useState('teaching');

  return (
    <div>
      <h1>Music</h1>
      <ul className='subNav navMusic'>
        <li>
          <button
            className='navButton red'
            onClick={() => setMusicTab('teaching')}
            style={{ background: musicTab === 'teaching' ? '#FF5757' : null }}>music lessons</button>
        </li>
        <li>
          <button
            className='navButton red'
            onClick={() => setMusicTab('soundDesign')}
            style={{ background: musicTab === 'soundDesign' ? '#FF5757' : null }}>sound design - soundtrack</button>
        </li>
        <li>
          <button
            className='navButton red'
            onClick={() => setMusicTab('audioVisual')}
            style={{ background: musicTab === 'audioVisual' ? '#FF5757' : null }}>audio/visual - installations</button>
        </li>
        <li>
          <button
            className='navButton red'
            onClick={() => setMusicTab('musicProduction')}
            style={{ background: musicTab === 'musicProduction' ? '#FF5757' : null }}>music production</button>
        </li>
        <li>
          <a href="https://etienne-machine.com/" target='_blank' rel='noreferrer'>
            <button className='navButton red'>etienne machine</button>
          </a>
        </li>
        {/* <li>
          <button
            className='navButton red'
            onClick={() => setMusicTab('about')}
            style={{ background: musicTab === 'about' ? '#FF5757' : null }}>about</button>
        </li> */}
      </ul>
      {musicTab === 'soundDesign' ? <SoundDesign /> : musicTab === 'audioVisual' ? <AudioVisual /> : musicTab === 'musicProduction' ? <MusicProduction /> : musicTab === 'etienneMachine' ? <EtienneMachine /> : musicTab === 'teaching' ? <Teaching /> : null}
    </div >
  )
}

const SoundDesign = () => {
  return (
    <div>
      <h2>Sound Design - Soundtrack</h2>
      <div className="row musicHeroRow">
        <div className="col-xl-6">
          <img src={soundDesignLogo} alt="sound design soundtrack" className='coursesImg' style={{ width: '100%' }} />
        </div>

        <div className="col-xl-6" style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ padding: '0' }}>
            Passionate about video games and pop culture, I've always had a fascination for movie soundtracks and sound effects.
            <br /><br />
            I can bring my expertise in composition, arrangement, sound synthesis and field recording to your project to make give it life and make it stand out!
            <br /><br />
            Contact me for any questions or to get a quote for your next project!
            <br /><br />
            <button className='contentButton red'
              onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Sountrack / Sound Design'}>contact me</button>
          </p>
        </div>
      </div>


      <h2>Metroid Dread</h2>
      <h3>Rescoring and Sound Design</h3>
      <div className="iframeContainer">
        <iframe src="https://www.youtube.com/embed/8qE9w7ZDFiI" title="Metroid Dread Trailer Rescore" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>

    </div>
  )
}

const AudioVisual = () => {
  return (
    <div>
      <h2>Audio/Visual - Installations</h2>
      <div className="row musicHeroRow">
        <div className="col-xl-6">
          <img src={audioVisualLogo} alt="audio visual installations" className='coursesImg' style={{ width: '100%' }} />
        </div>

        <div className="col-xl-6" style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ padding: '0' }}>
            One of the important starting points for my interractive experiences is the use of unconventional methods to control sound synthesis and sound processing.
            <br /><br />
            To that effect I have developped a video game and a local network chatroom capable of controlling synths and effects.
            <br /><br />
            Contact me for commissions or collaborations!
            <br /><br />
            <button className='contentButton red'
              onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Audio-visual / Installations'}>contact me</button>
          </p>
        </div>
      </div>


      <h2>Video Game</h2>
      <h3>Controlling sound</h3>
      <div className="iframeContainer">
        <iframe src="https://www.youtube.com/embed/Ez3EcpOLJLk" title="DOOM-type game engine as controller for MAX MSP" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <p style={{ marginTop: '1rem' }}>
        recreation of the DOOM video game engine using python. the movements of the character and position on the map determine the resulting sounds.
      </p>

    </div>
  )
}

const MusicProduction = () => {
  return (
    <div>
      <h2>Music Production</h2>
      <div className="row musicHeroRow">
        <div className="col-xl-6">
          <img src={musicProductionLogo} alt="music production" className='coursesImg' style={{ width: '100%' }} />
        </div>

        <div className="col-xl-6" style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ padding: '0' }}>
            My expertise in composition, arrangement and sound design can greatly benefit your music!
            <br /><br />
            I can produce music for you based on sketches you have or from scratch, making extensive use of Ableton and Max for Live to craft a unique sound you won't easily find elsewhere.
            <br /><br />
            Contact me for information or quotes!
            <br /><br />
            <button className='contentButton red'
              onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Music Production'}>contact me</button>
          </p>
        </div>
      </div>

      <h2>Unreleased EP</h2>
      <div className="iframeContainer">
        <iframe width="100%" title='soundcloudEmbed' scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1648573594%3Fsecret_token%3Ds-acCPFxB6FQZ&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      </div>

      <h2 style={{ marginTop: '1rem' }}>X-MEN</h2>
      <h3>Intro Cover</h3>
      <div className="iframeContainer">
        <iframe src="https://www.youtube.com/embed/KlGcQOgCTh4" title="X MEN INTRO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div >
  )
}

const EtienneMachine = () => {
  return (
    <div>
      <h2>Etienne Machine</h2>
    </div>
  )
}

const Teaching = () => {
  return (
    <div>
      <h2>Music Lessons</h2>
      <div className="row musicHeroRow">
        <div className="col-xl-6">
          <img className='coursesImg' src={teachingThumbnail} alt='music lessons'
            style={{ width: '100%' }} />
        </div>

        <div className="col-xl-6" style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ padding: '1rem 0' }}>
            Koninklijk Conservatorium Graduate based in the Hague with 15+ years of experience playing, recording, composing, arranging, programming and teaching music of various styles.
            <br /><br />
            The lessons can be taught in English or French, online or in person. All levels and ages are welcome.
            <br /><br />
            Online or in person (in the Hague)
          </p>
        </div>
      </div>

      <div className="coursesList container col-12">


        <div class="accordion" id="accordionMusicLessons">
          <div className="row">
            <div class="accordion-item col-xl-6">
              <div class="accordion-header">

                <img src={piano} alt="piano/keyboards" className='coursesImg' />
                <h3>Piano / Keyboards</h3>
                <p>Learn how to play the piano and/or synthesizers from sheet music, by ear, or by improvising your own music!</p>
                <button class="contentButton red" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  see courses
                </button>

              </div>
              <div id="collapseOne" class="accordion-collapse collapse courseCollapse" data-bs-parent="#accordionMusicLessons">
                <div class="accordion-body">
                  <div className="row">

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>Beginner</h3>
                        <em>Piano / Keyboards</em>
                        <em><br />8 weeks</em>
                        <em><br />45 minutes</em>
                        <h2>380€</h2>
                        <ul>
                          <li>Get to know the instrument</li>
                          <li>Learn simple melodies by ear and start improvising</li>
                          <li>Learn how to read sheet music</li>
                          <li>Technique and dexterity</li>
                          <li>Play songs with both hands</li>
                          <li>The use of the pedal</li>
                          <li>Basic chords</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Piano Lesson Beginner'}>book trial lesson</button>

                      </div>
                    </div>

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>Punchcard 10 Lessons</h3>
                        <em>Piano / Keyboards</em>
                        <em><br />all levels above beginner</em>
                        <em><br />45 minutes</em>
                        <h2>450€</h2>
                        <p>Tailor made lessons depending on your level and goals.</p>
                        <ul>
                          <li>Technique</li>
                          <li>Interpretation</li>
                          <li>Improvisation</li>
                          <li>Any style</li>
                          <li>And more</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Piano Lesson 10'}>book trial lesson</button>

                      </div>
                    </div>

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>Punchcard 4 Lessons</h3>
                        <em>Piano / Keyboards</em>
                        <em><br />all levels above beginner</em>
                        <em><br />45 minutes</em>
                        <h2>190€</h2>
                        <p>Tailor made lessons depending on your level and goals.</p>
                        <ul>
                          <li>Technique</li>
                          <li>Interpretation</li>
                          <li>Improvisation</li>
                          <li>Any style</li>
                          <li>And more</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Piano Lesson 4'}>book trial lesson</button>

                      </div>
                    </div>

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>1 Lesson</h3>
                        <em>Piano / Keyboards</em>
                        <em><br />all levels above beginner</em>
                        <em><br />45 minutes</em>
                        <h2>50€</h2>
                        <p>Tailor made lessons depending on your level and goals.</p>
                        <ul>
                          <li>Technique</li>
                          <li>Interpretation</li>
                          <li>Improvisation</li>
                          <li>Any style</li>
                          <li>And more</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Piano Lesson 1'}>book trial lesson</button>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item col-xl-6">
              <div class="accordion-header">

                <img src={theory} alt="theory" className='coursesImg' />
                <h3>Music Theory</h3>
                <p>Learn how to understand and analyse music as well as key concepts that will help you write your own with confidence!</p>
                <button class="contentButton red collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  see courses
                </button>

              </div>
              <div id="collapseTwo" class="accordion-collapse collapse courseCollapse moveCollapseLeft" data-bs-parent="#accordionMusicLessons">
                <div class="accordion-body">

                  <div className="row">

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>Beginner</h3>
                        <em>Music Theory</em>
                        <em><br />8 weeks</em>
                        <em><br />45 minutes</em>
                        <h2>380€</h2>
                        <ul>
                          <li>Learn how to read sheet music</li>
                          <li>Learn the construction of scales</li>
                          <li>Time signatures</li>
                          <li>Key signatures</li>
                          <li>Circle of fifth</li>
                          <li>Chords</li>
                          <li>Ear training</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Theory Lesson Beginner'}>book trial lesson</button>

                      </div>
                    </div>

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>Punchcard 10 Lessons</h3>
                        <em>Music Theory</em>
                        <em><br />all levels above beginner</em>
                        <em><br />45 minutes</em>
                        <h2>450€</h2>
                        <p>Tailor made lessons depending on your level and goals.</p>
                        <ul>
                          <li>Solfege</li>
                          <li>Harmony</li>
                          <li>Ear training</li>
                          <li>And more</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Theory Lesson 10'}>book trial lesson</button>

                      </div>
                    </div>

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>Punchcard 4 Lessons</h3>
                        <em>Music Theory</em>
                        <em><br />all levels above beginner</em>
                        <em><br />45 minutes</em>
                        <h2>190€</h2>
                        <p>Tailor made lessons depending on your level and goals.</p>
                        <ul>
                          <li>Solfege</li>
                          <li>Harmony</li>
                          <li>Ear training</li>
                          <li>And more</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Theory Lesson 4'}>book trial lesson</button>

                      </div>
                    </div>

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>1 Lesson</h3>
                        <em>Music Theory</em>
                        <em><br />all levels above beginner</em>
                        <em><br />45 minutes</em>
                        <h2>50€</h2>
                        <p>Tailor made lessons depending on your level and goals.</p>
                        <ul>
                          <li>Solfege</li>
                          <li>Harmony</li>
                          <li>Ear training</li>
                          <li>And more</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Theory Lesson 1'}>book trial lesson</button>

                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
            <div class="accordion-item col-xl-6">
              <div class="accordion-header">

                <img src={ableton} alt="ableton" className='coursesImg' />
                <h3>Ableton Live</h3>
                <p>Learn how to record and arrange your musical ideas in Ableton Live as well as the basics of mixing to bring your music to life!</p>
                <button class="contentButton red collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  see courses
                </button>


              </div>
              <div id="collapseThree" class="accordion-collapse collapse courseCollapse" data-bs-parent="#accordionMusicLessons">
                <div class="accordion-body">

                  <div className="row">

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>Beginner</h3>
                        <em>Ableton Live</em>
                        <em><br />8 weeks</em>
                        <em><br />90 minutes</em>
                        <h2>760€</h2>
                        <ul>
                          <li>Get to know the interface</li>
                          <li>MIDI Basics</li>
                          <li>Audio recoding</li>
                          <li>Editing</li>
                          <li>Effects</li>
                          <li>Intro to sampling</li>
                          <li>Intro to mixing</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Ableton Live Lesson Beginner'}>book trial lesson</button>

                      </div>
                    </div>

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>Punchcard 10 Lessons</h3>
                        <em>Ableton Live</em>
                        <em><br />all levels above beginner</em>
                        <em><br />90 minutes</em>
                        <h2>900€</h2>
                        <p>Tailor made lessons depending on your level and goals.</p>
                        <ul>
                          <li>Production techniques</li>
                          <li>Composition / arrangement</li>
                          <li>Sound design</li>
                          <li>And more</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Ableton Live Lesson 10'}>book trial lesson</button>

                      </div>
                    </div>

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>Punchcard 4 Lessons</h3>
                        <em>Ableton Live</em>
                        <em><br />all levels above beginner</em>
                        <em><br />90 minutes</em>
                        <h2>380€</h2>
                        <p>Tailor made lessons depending on your level and goals.</p>
                        <ul>
                          <li>Production techniques</li>
                          <li>Composition / arrangement</li>
                          <li>Sound design</li>
                          <li>And more</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Ableton Live Lesson 4'}>book trial lesson</button>

                      </div>
                    </div>

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>1 Lesson</h3>
                        <em>Ableton Live</em>
                        <em><br />all levels above beginner</em>
                        <em><br />90 minutes</em>
                        <h2>100€</h2>
                        <p>Tailor made lessons depending on your level and goals.</p>
                        <ul>
                          <li>Production techniques</li>
                          <li>Composition / arrangement</li>
                          <li>Sound design</li>
                          <li>And more</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Ableton Live Lesson 1'}>book trial lesson</button>

                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <div class="accordion-item col-xl-6">
              <div class="accordion-header">

                <img src={maxForLive} alt="max for live" className='coursesImg' />
                <h3>Max for Live</h3>
                <p>Take your sound to the next level: learn how to make your own virtual instruments and effects and sound like no one else!</p>
                <button class="contentButton red collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  see courses
                </button>

              </div>
              <div id="collapseFour" class="accordion-collapse collapse courseCollapse moveCollapseLeft" data-bs-parent="#accordionMusicLessons">
                <div class="accordion-body">

                  <div className="row">

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>Beginner</h3>
                        <em>Max for Live</em>
                        <em><br />8 weeks</em>
                        <em><br />90 minutes</em>
                        <h2>760€</h2>
                        <ul>
                          <li>Get to know the interface</li>
                          <li>Make a simple MIDI effect</li>
                          <li>Learn synthesis by making your first virtual instrument</li>
                          <li>Understand the different types of data used to make an digital instrument</li>
                          <li>Additive / substractive synthesis</li>
                          <li>FM / AM synthesis</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Max for Live Lesson Beginner'}>book trial lesson</button>

                      </div>
                    </div>

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>Punchcard 10 Lessons</h3>
                        <em>Max for Live</em>
                        <em><br />all levels above beginner</em>
                        <em><br />90 minutes</em>
                        <h2>900€</h2>
                        <p>Tailor made lessons depending on your level and goals.</p>
                        <ul>
                          <li>Different synthesis techniques</li>
                          <li>Granular synthesis</li>
                          <li>Communicate between apps</li>
                          <li>Patch optimization</li>
                          <li>And more</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Max for Live Lesson 10'}>book trial lesson</button>

                      </div>
                    </div>

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>Punchcard 4 Lessons</h3>
                        <em>Max for Live</em>
                        <em><br />all levels above beginner</em>
                        <em><br />90 minutes</em>
                        <h2>380€</h2>
                        <p>Tailor made lessons depending on your level and goals.</p>
                        <ul>
                          <li>Different synthesis techniques</li>
                          <li>Granular synthesis</li>
                          <li>Communicate between apps</li>
                          <li>Patch optimization</li>
                          <li>And more</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Max for Live Lesson 4'}>book trial lesson</button>

                      </div>
                    </div>

                    <div class="card card-body col-xl-6">
                      <div className='courseCardBody'>

                        <h3>1 Lesson</h3>
                        <em>Max for Live</em>
                        <em><br />all levels above beginner</em>
                        <em><br />90 minutes</em>
                        <h2>100€</h2>
                        <p>Tailor made lessons depending on your level and goals.</p>
                        <ul>
                          <li>Different synthesis techniques</li>
                          <li>Granular synthesis</li>
                          <li>Communicate between apps</li>
                          <li>Patch optimization</li>
                          <li>And more</li>
                        </ul>
                        <button style={{ marginBottom: '0' }} className='contentButton red'
                          onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Booking Max for Live Lesson 1'}>book trial lesson</button>

                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

const Web = () => {

  return (
    <div>
      <h1>Web</h1>

      <div className="row musicHeroRow">
        <div className="col-xl-6">
          <img src={webThumbnail} alt="web thumbnail" className='coursesImg' style={{ width: '100%' }} />
        </div>
        <div className="col-xl-6" style={{ display: 'flex', alignItems: 'center' }}>
          <h2>Give your work the online presence it deserves!</h2>
        </div>
      </div>

      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button style={{ margin: '0', padding: '0.5rem 0' }} className='contentButton yellowRev' type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
              Why choose me for your website?
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse">
            <div className="accordion-body">
              <h3>Expertise</h3>
              <p>
                I am an experienced web designer and developer, and I am active in different artistic fields, from visual arts to music. Everything I do, I do with an artistic mind and it will be no different for the design of your website.
              </p>
              <h3 style={{ marginTop: '1rem' }}>Value your time</h3>
              <p>
                Save your time for what you do best and let me worry about presenting it in the best possible way.
              </p>
              <h3 style={{ marginTop: '1rem' }}>Custom design</h3>
              <p>
                With your knowledge of your product and my experience in designing web experiences we will create the best design to make your product shine.
              </p>
              <h3 style={{ marginTop: '1rem' }}>The freedom to choose only what you need</h3>
              <p>
                My offer is varried to fit the different situations you might be in. Maybe the content on your website will stay the same, or maybe you will need me to update the content later down the line. Maybe you will want to personally update your content or you want me to add a feature to your website after the delivery. See my prices below to see what I have in store.
              </p>
              <h3 style={{ marginTop: '1rem' }}>Responsive design</h3>
              <p>
                All my websites are responsive, meaning they look good on any device of any screen size. But it doesn't have to stop at adapting the layout to the screen size, the experience can also differ from computer to mobile device, much like this very website.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button style={{ margin: '0', padding: '0.5rem 0' }} className='contentButton yellowRev' type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
              Some of my work
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
            <div className="accordion-body">

              <div className="coursesList container col-12">
                <div className="row">
                  <div className="col-xl-6">
                    <h3>Band Website</h3>
                    <img src={etienneMachineScreenshot} alt="etienne machine" className='coursesImg' />
                    <a className='contentButtonLink' href="https://etienne-machine.com/" target='_blank' rel='noreferrer'>
                      <div className='contentButton yellow'>
                        visit website
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-6">
                    <h3>Music Lessons</h3>
                    <img src={asherScreenshot} alt="Asher Varadi" className='coursesImg' />
                    <a className='contentButtonLink' href="https://jpkvoid.github.io/asher/" target='_blank' rel='noreferrer'>
                      <div className='contentButton yellow'>
                        visit website
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-6">
                    <h3>Recipe Index</h3>
                    <img src={recipeScreenshot} alt="Recipe Index" className='coursesImg' />
                    <a className='contentButtonLink' href="https://jpkvoid.github.io/recipe-index/" target='_blank' rel='noreferrer'>
                      <div className='contentButton yellow'>
                        visit website
                      </div>
                    </a>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button style={{ margin: '0', padding: '0.5rem 0' }} className='contentButton yellowRev' type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
              Price list
            </button>
          </h2>
          <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
            <div className="accordion-body">

              <div className="coursesList container col-12">
                <div className="row">
                  <div className="col-xl-6">
                    <h3>One Pager</h3>
                    <h2>400€*<em style={{ fontSize: '1rem' }}>excl. VAT</em></h2>
                    <ul>
                      <li>1 page</li>
                      <li>custom design</li>
                      <li>design of thumbnails if needed</li>
                    </ul>
                    <button className='contentButton yellowRev'
                      onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Budget Website Order'}>order now</button>
                  </div>

                  <div className="col-xl-6">
                    <h3>Standard</h3>
                    <h2>700€*<em style={{ fontSize: '1rem' }}>excl. VAT</em></h2>
                    <ul>
                      <li>up to 5 pages</li>
                      <li>custom design</li>
                      <li>design of thumbnails if needed</li>
                    </ul>
                    <button className='contentButton yellowRev'
                      onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Standard Website Order'}>order now</button>
                  </div>

                  <div className="col-xl-6">
                    <h3>XL</h3>
                    <h2>1000€*<em style={{ fontSize: '1rem' }}>excl. VAT</em></h2>
                    <ul>
                      <li>up to 10 pages</li>
                      <li>custom design</li>
                      <li>design of thumbnails if needed</li>
                    </ul>
                    <button className='contentButton yellowRev'
                      onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Standard Website Order'}>order now</button>
                  </div>
                </div>
              </div>

              <em>*prices may vary depending on the size of the project</em>

            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button style={{ margin: '0', padding: '0.5rem 0' }} className='contentButton yellowRev' type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
              Additional services
            </button>
          </h2>
          <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse">
            <div className="accordion-body">

              <div className="coursesList container col-12">
                <div className="row">
                  <div className="col-xl-6">
                    <h3>CMS</h3>
                    <h2>100€<em style={{ fontSize: '1rem' }}>excl. VAT</em></h2>
                    <p>
                      Do you often need to update the content of your website yourself? I will then setup a CMS (Content Management System) for you to edit the texts and pictures yourself!
                    </p>
                    <button className='contentButton yellowRev'
                      onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=CMS Order'}>order now</button>
                  </div>

                  <div className="col-xl-6">
                    <h3>Editor</h3>
                    <h2>30€/hour<em style={{ fontSize: '1rem' }}>excl. VAT</em></h2>
                    <p>
                      Do you need your website edited after the delivery but do not have the time to do it yourself? Hire me to keep your website up to date!
                    </p>
                    <button className='contentButton yellowRev'
                      onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Website Edition Order'}>order now</button>
                  </div>

                  <div className="col-xl-6">
                    <h3>Maintenance</h3>
                    <h2>50€/hour<em style={{ fontSize: '1rem' }}>excl. VAT</em></h2>
                    <p>
                      Need a feature added, an extra tab or section to your website, or any other after delivery request? This pack let's you order just that!
                    </p>
                    <button className='contentButton yellowRev'
                      onClick={() => window.location = 'mailto:jpkvoid@gmail.com?subject=Maintenance Order'}>order now</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

const About = () => {
  return (
    <div>
      <h1>About Me</h1>

      <div className="row aboutHeroRow">
        <div className="col-xl-6">
          <img src={julienSquare} alt="about me" className='coursesImg' style={{ width: '100%' }} />
        </div>
        <div className="col-xl-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h2>Julien <span style={{ color: '#004AAD' }}>////////////</span>
            <br />Palluel <span style={{ color: '#004AAD' }}>///////////</span>
            <br />Kochnitzky <span style={{ color: '#004AAD' }}>//////</span>
            <br />
            <br />
            <a href="https://www.instagram.com/jpk_void/" target='_blank' rel='noreferrer'>
              <i class="fa-brands fa-instagram" style={{ color: '#004AAD', marginRight: '1rem' }}></i>
            </a>
            <a href="mailto:jpkvoid@gmail.com" target='_blank' rel='noreferrer'>
              <i class="fa-regular fa-envelope" style={{ color: '#004AAD', marginRight: '1rem' }}></i>
            </a>
            <a href="https://www.linkedin.com/in/julien-palluel-157855280/" target='_blank' rel='noreferrer'>
              <i class="fa-brands fa-linkedin" style={{ color: '#004AAD' }}></i>
            </a>
          </h2>

        </div>
      </div>

      <p>
        <h3>Passionate about music and technology,</h3>my approach to both music and web design is highly personal.
        <br /><br />
        Graduating from the Royal Conservatoire of the Hague in both Jazz Piano and Sonology, my skills encompass playing, composing, arranging, producing and teaching music at the highest level. My love for technology brought me to learn various music software for sound design and composition, including experimental ones, allowing me to craft my sound using tools that are not widely used on the market. With this approach my output has a unique quality that is hard to find elsewhere.
        <br /><br />
        I also teach all this knowledge I gathered over the years, from piano to music production and theory, even more experimental approaches to making music for the more adventurous, I offer varied courses for all levels and ages.
        <br /><br />
        My passion for technology also brought me to learn web development and web design. At first it was a way for me to explore making more interractive and out-of-the-box musical experiences, but I quickly took to learning web technologies and design principles in order to present my work online in the best way possible.
        <br /><br />
        Don't hesitate to contact me for any artistic, teaching or web related project!
      </p>

      <p style={{ margin: '4rem 0' }}>© jpkvoid 2023</p>
    </div>
  )
}

export default App;
